import Vue from 'vue';
import ElementUI from 'element-ui';
// import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/display.css'; //页面隐藏样式
import './assets/css/element-theme/index.css'; //经过配置的主题文件
// import "./assets/css/element-theme/index-3.css"; //经过配置的主题文件
import './assets/css/main.css'; //主要公用样式文件
// import "./assets/css/main-3.css"; //主要公用样式文件
import './assets/css/Responsive.css'; //响应式文件

import App from './App.vue';
import router from './router';
import store from './store';
import VueCookies from 'vue-cookies'; //cookie
import VueCanvasPoster from 'vue-canvas-poster'; // 海报生成
import tinymce from 'tinymce' // 富文本
import VueTinymce from '@packy-tang/vue-tinymce' // 富文本

// 富文本
//样式
// import "tinymce/skins/content/default/content.min.css"; //默认模式下添加并不会起效果，开启内联模式时，请加上。
import 'tinymce/skins/ui/oxide/skin.min.css'
// import 'tinymce/skins/ui/oxide/content.min.css' //此处引用内容为编辑器文本区域的内部样式，需在编辑器设置中引入。

//主题
import 'tinymce/themes/silver'
//插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
import 'tinymce/icons/default/icons'

//本地化
import './utils/tinymce/langs/zh_CN.js'
// 富文本
Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

Vue.config.productionTip = false;
// 注册组件库
Vue.use(VueCanvasPoster);
Vue.use(ElementUI);
Vue.use(VueCookies);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
