<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getAllDict } from '@/utils/dict.js';
export default {
  created() {
    // 判断是否登录
    // 可以不登录就打开的页面:登录、注册、忘记密码、重置密码
    let noTokenUrl = ['#/Login', '#/Register', '#/Forget', '#/Reset'];
    //获取登录状态
    if (this.$cookies.get('token')) {
      //有登录状态，不动
      this.loginType = true;
    } else {
      if (!noTokenUrl.includes(window.location.hash)) {
        //没有登录状态，并且需要登录，去登录页
        this.loginType = false;
        window.location.href = '#/Login';
      }
    }
    // 获取系统内用到的各类字典
    if (!this.$cookies.get('racing_track')) {
      //获取赛道字典
      getAllDict('racing_track');
    }
    if (!this.$cookies.get('registration_type')) {
      //获取登记类型字典
      getAllDict('registration_type');
    }
  },
  methods: {},
};
</script>

<style></style>
